@import "../../assets/styles/utils";

header{
  &.scroll-header{
    .header-zhanwei{
      @include res(height, 90px, 60 / 90);
    }
    .header{
      box-shadow: 0 0 15px rgba(0,0,0,.1);
      @include res(height, 90px, 60 / 90);
    }
  }
  .header-zhanwei{
    transition: all .3s;
    @include res(height, $header-height-base, $header-height-ratio);
  }
  .header{
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .3s;
    @include res(padding-left,100px, 10 / 100);
    @include res(padding-right,100px, 10 / 100);
    @include res(height, $header-height-base, $header-height-ratio);
    .left{
      position: relative;
      img{
        transition: all .3s;
        display: block;
        @include res(width,405px, 100 / 405);
        @media only screen and (width:1680px) {
          width: 280px;
        }
        @media only screen and (width:1440px) {
          width: 270px;
        }
        @media only screen and (width:1280px) {
          width: 250px;
        }
        &.white-logo{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
          opacity: 0;
        }
      }
    }
    .right{
      align-items: center;
      height: 100%;
      @include res(display,flex,(md:none));
      @include res(font-size, 20px, 14 / 20);
      @media screen and (max-width:1440px) {
        @include res(font-size, 18px, 12 / 18);
      }
      >a{
        height: 100%;
        display: flex;
        align-items: center;
        transition: all .3s;
        @include res(margin-right,40px, 0 / 40);
        &:hover,&.active{
          color: $color-main;
        }
      }
      .language{
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        &:hover{
          .title{
            color: $color-main;
          }
          .sub{
            opacity: 1;
            visibility: visible;
          }
        }
        .title{
          color: $color-desc;
          cursor: pointer;
          transition: all .3s;
          @include res(font-size, 16px, 12 / 16);
          .iconfont{
            margin-right: 5px;
            margin-top: -5px;
            @include res(font-size, 24px, 18 / 24);
          }
        }
        .sub{
          position: absolute;
          top: 70%;
          right: 0%;
          white-space: nowrap;
          border-radius: 10px;
          background-color: #fff;
          opacity: 0;
          visibility: hidden;
          transition: all .3s;
          box-shadow: 0 0 10px rgba(0,0,0,.1);
          @include res(padding-left, 25px, 10 / 25);
          @include res(padding-right, 25px, 10 / 25);
          @include res(padding-top, 15px, 10 / 15);
          @include res(padding-bottom, 15px, 10 / 15);
          a{
            color: #000;
            display: block;
            transition: all .3s;
            @include res(font-size, 16px, 12 / 16);
            &:not(:last-child){
              @include res(margin-bottom,10px, 5 / 10);
            }
            &:hover{
              color: $color-main;
            }
          }
        }
      }
    }
    .open-menu{
      @include res(display,none,(md:block));
    }
  }
  .mob-nav-mask{
    position: fixed;
    z-index: 98;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    background-color: rgba(0,0,0,.6);
  }
  .mob-nav{
    position: fixed;
    z-index: 99;
    height: 100%;
    top: 0;
    left: 100%;
    background-color: #fff;
    box-sizing: border-box;
    overflow-y: auto;
    transition: all .3s;
    @include res(padding-top,$header-height-base, $header-height-ratio);
    @include res(padding-left, 50px, 15 / 50);
    @include res(padding-right, 50px, 15 / 50);
    @include res(padding-bottom, 30px, 20 / 30);
    @include res(width, 40%,(sm:60%,xs:90%));
    &.active{
      transform: translateX(-100%);
    }
    >a{
      display: block;
      line-height: 2;
      border-top: 1px solid rgba($color-main,.1);
      padding: 10px;
      transition: all .3s;
      @include res(font-size, 20px, 18 / 20);
      &:hover,&.active{
        color: $color-main;
      }
      &:first-child{
        border-top:none;
        @include res(margin-top, 30px, 20 / 30);
      }
      
    }
    .language{
      .title{
        color: $color-desc;
        display: flex;
        align-items: center;
        padding: 10px;
        @include res(font-size, 16px);
        .iconfont{
          margin-right: 5px;
          margin-top: -5px;
          @include res(font-size, 24px, 18 / 24);
        }
      }
      .sub{
        // display: flex;
        // flex-wrap: wrap;
        a{
          // width: 50%;
          // box-sizing: border-box;
          display: block;
          border-bottom: 1px solid rgba($color-main,.05);
          padding: 10px 0 10px 25px;
        }
      }
    }
  }
}
