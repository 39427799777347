@font-face {
  font-family: "iconfont"; /* Project id 3635050 */
  src: url('iconfont.woff2?t=1663291997850') format('woff2'),
       url('iconfont.woff?t=1663291997850') format('woff'),
       url('iconfont.ttf?t=1663291997850') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-shouye:before {
  content: "\e637";
}

.icon-gongsixinxi:before {
  content: "\e67c";
}

.icon-biaoji:before {
  content: "\e672";
}

.icon-sousuo-m:before {
  content: "\e60b";
}

.icon-youxiang:before {
  content: "\e605";
}

.icon-jiangbei-:before {
  content: "\e604";
}

.icon-qiyelinian:before {
  content: "\e614";
}

.icon-jiazhi:before {
  content: "\e619";
}

.icon-fuzeren:before {
  content: "\e688";
}

.icon-dizhi:before {
  content: "\e646";
}

.icon-24gf-telephone2:before {
  content: "\e96d";
}

.icon-24gl-playCircle:before {
  content: "\ea6e";
}

.icon-24gl-fileText:before {
  content: "\eabe";
}

.icon-xiazai:before {
  content: "\e703";
}

.icon-_sixiangpinde:before {
  content: "\e61b";
}

.icon-youxiang1:before {
  content: "\e7dc";
}

.icon-tuite:before {
  content: "\ee49";
}

.icon-diqiu:before {
  content: "\e60a";
}

.icon-jianyou_you:before {
  content: "\e8d4";
}

.icon-jiantou_xiangyou:before {
  content: "\eb08";
}

.icon-jianyou_shang:before {
  content: "\eb09";
}

.icon-jianyou_zuo:before {
  content: "\eb0a";
}

