@import "../../assets/styles/utils";

footer{
  position: relative;
  @include res(padding-right,100px, 0 / 100);
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 80%;
    background-color: #348281;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  .footer{
    background: url(./images/footer_bg.jpg) no-repeat top center $color-main;
    background-size: cover;
    position: relative;
    @include res(padding-left, 215px, 10 / 215);
    @include res(padding-right, 150px, 10 / 150);
    @include res(padding-top, 60px, 15 / 60);
    @include res(padding-bottom, 40px, 15 / 40);
    @include res(border-top-right-radius, 70px, 20 / 70);
    .footer-logo{
      @include res(margin-bottom,70px, 20 / 70);
      img{
        opacity: .4;
        display: block;
        @include res(width,405px, 200 / 405);
        @include res(margin,null,(xs:0 auto));
      }
    }
    .footer-nav{
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      @include res(line-height, 2, 1.6 / 2);
      @include res(display,flex,(xs:block));
      dl{
        @include res(margin-top,0,(xs:15px));
        dt{
          color: #fff;
          font-family: 'hs-m';
          @include res(font-size,30px, 18 / 30);
          @include res(margin-bottom, 40px, 10 / 40);
          @include res(text-align,null,(xs:center));
        }
        dd{
          font-family: 'hs-l';
          color: rgba(255,255,255,.7);
          @include res(margin-bottom,10px, 5 / 10);
          a{
            transition: all .3s;
          }
        }
        &.link{
          @include res(display,null,(xs:none));
        }
        &:not(.contact){
          dd{
            a{
              display: inline-block;
              &:hover{
                color: #fff;
                transform: translateX(5px);
              }
            }
          }
        }
        &.contact{
          @include res(max-width,240px,(xs:100%));
          dd{
            display: flex;
            align-items: flex-start;
            .iconfont{
              @include res(font-size,20px, 18 / 20);
              @include res(margin-right, 10px);
              @include res(margin-top, -5px);
            }
            a{
              &:hover{
                color: #fff;
              }
            }
          }
        }
        &.form-cont{
          @include res(width, 35%,(md: 50%,sm:80%,xs:100%));
          .form{
            form{
              align-items: stretch;
              justify-content: space-between;
              flex-wrap: wrap;
              @include res(display,flex);
            }
            .form-item{
              flex-shrink: 0;
              position: relative;
              z-index: 1;
              @include res(width, 48%);
              @include res(margin-bottom, 10px, 5 / 10);
              &.full{
                width: 100%;
              }
              &.active{
                label{
                  font-size: 14px;
                  @include res(top,-14px, -8 / -14);
                }
              }
              label{
                position: absolute;
                left: 2px;
                top: 8px;
                z-index: -1;
                transition: $anime-duration $anime-bezier;
                color: rgba(255,255,255,.6);
                font-family: 'hs-l';
                @include res(font-size, 18px, 14 / 18);
                @include res(line-height,40px, 30 / 40);
                span{
                  color: $color-main;
                }
              }
              input{
                width: 100%;
                margin-top: 10px;
                background: none;
                border: none;
                outline: none;
                color: #fff;
                border-bottom: 1px solid rgba(255,255,255,.6);
                @include res(height,40px, 30 / 40);
              }
              button{
                width: 100%;
                background-color: #44adab;
                border-radius: 3px;
                color: #fff;
                font-family: 'hs-m';
                border: none;
                cursor: pointer;
                padding-top: 5px;
                transition: all .3s;
                @include res(font-size,18px, 16 / 18);
                @include res(height,38px);
                @include res(margin-top, 30px, 10 / 30);
                &:hover{
                  background-color: #fff;
                  color: $color-main;
                }
              }
            }
          }
        }
      }
    }
    .backtop{
      position: absolute;
      right: 3%;
      bottom: 16%;
      text-align: center;
      z-index: 1;
      cursor: pointer;
      transition: all .3s;
      @include res(display,null,(xs:none));
      &:hover{
        transform: translateY(-10px);
      }
      .iconfont{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #fff;
        color: $color-main;
        font-weight: bold;
        @include res(width,44px);
        @include res(height,44px);
        @include res(margin-bottom, 10px, 5 / 10);
      }
      span{
        color: #fff;
        text-transform: uppercase;
        font-family: 'hs-m';
        @include res(font-size, 20px, 14 / 20);
      }
    }
  }
  .copyright{
    background-color: #42b3b0;
    align-items: center;
    justify-content: space-between;
    color: rgba(255,255,255,.7);
    @include res(text-align,null,(xs:center));
    @include res(display,flex,(xs:block));
    @include res(font-size,18px, 14 / 18);
    @include res(padding-left, 215px, 10 / 215);
    @include res(padding-right,50px, 10 / 50);
    @include res(padding-top,25px, 10 / 25);
    @include res(padding-bottom,25px, 10 / 25);
    .left{
      font-family: 'hs-l';
    }
    .right{
      @include res(margin-top,null,(xs:10px));
      span{
        margin: 0 10px;
        @include res(display,null,(md:none));
      }
      a{
        transition: all .3s;
        &:hover{
          color: #fff;
        }
        &.site-map{
          @include res(display,null,(md:none));
        }
      }
    }
  }
}