@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'hs-l';
  src: url('../fonts/HisenseSansAlfabet-Light.woff') format('woff'),
    url('../fonts/HisenseSansAlfabet-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'hs-r';
  src: url('../fonts/HisenseSansAlfabet-Regular.woff') format('woff'),
    url('../fonts/HisenseSansAlfabet-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'hs-m';
  src: url('../fonts/HisenseSansAlfabet-Medium.woff') format('woff'),
    url('../fonts/HisenseSansAlfabet-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'hs-b';
  src: url('../fonts/HisenseSansAlfabet-Bold.woff') format('woff'),
    url('../fonts/HisenseSansAlfabet-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}

body {
  font-family: 'hs-r', '微软雅黑';
  overflow-x: hidden;
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, $text-size-base, $text-size-ratio);
}
.comp-root{
  overflow-x: hidden;
}

//清除浮动
.clear {
  zoom: 1;
  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}


//主体内容部分宽度
.container {
  box-sizing: border-box;
  width: 100%;
  // max-width: 1700px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);

  &.container-1200 {
    max-width: 1200px;
  }
}

.mt-115{
  @include res(margin-top, 115px, 20 / 115);
}
.mb-115{
  @include res(margin-bottom, 115px, 20 / 115);
}

// 列表中图片有占位图片的
.haszw-img{
  position: relative;
  overflow: hidden;
  img{
    display: block;
    width: 100%;
    height: auto;
    opacity: 0;
  }
  b,a{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    transition: all .3s;
  }
}
.wow{
  animation-delay: .1s;
}
.common-circle{
  position: absolute;
  z-index: -1;
  border-radius: 50%;
  background-color: $color-main-light;
  animation-delay: .5s;
  @include res(width,600px, 220 / 600);
  @include res(height,600px, 220 / 600);
}

//内页banner
.ny-banner{
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  z-index: 2;
  @include res(border-bottom-left-radius, 60px, 20 / 60);
  @include res(height,480px, 200 / 480);
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .cont{
    height: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    color: #fff;
    @include res(padding-top,30px, 15 / 30);
    @include res(padding-bottom,80px, 20 / 80);
    .crumbs{
      font-family: 'hs-l';
      display: flex;
      align-items: center;
      @include res(font-size, 14px, 12 / 14);
      span{
        margin: 0 5px;
      }
      a{
        transition: all .3s;
        &:hover{
          color: $color-main;
        }
      }
    }
    .title{
      width: 100%;
      position: relative;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      @include res(display,flex,(xs:block));
      h1{
        font-family: 'hs-m';
        @include res(font-size, 90px, 30 / 90);
      }
      .banner-search{
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        border-bottom: solid #fff;
        @include res(margin-left, 30px,(xs:0));
        @include res(width,640px,(md:450px,sm:420px,xs:100%));
        @include res(border-bottom-width, 2px, 1 / 2);
        input{
          flex-grow: 1;
          font-family: 'hs-m';
          color: #fff;
          border: none;
          background: none;
          @include res(font-size, 20px, 16 / 20);
          &::-webkit-input-placeholder{
            color: #fff;
          }
          &:-moz-placeholder{
            color: #fff;
          }
          &::moz-placeholder{
            color: #fff;
          }
          &:-ms-input-placeholder{
            color: #fff;
          }
        }
        button{
          cursor: pointer;
          border: none;
          background: none;
          .iconfont{
            color: #fff;
            @include res(font-size,46px, 28 / 46);
          }
        }
      }
      .pro-nav{
        top: 100%;
        left: 0;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        white-space: nowrap;
        @include res(width,null,(sm:100%));
        @include res(position,absolute,(sm:static));
        a{
          padding-left: 20px;
          font-family: 'hs-l';
          position: relative;
          transition: all .3s;
          @include res(margin-right,20px);
          @include res(margin-bottom,null,(sm:10px,xs:5px));
          @include res(font-size, 22px, 16 / 22);
          &::after{
            content: '';
            display: block;
            border-radius: 50%;
            background-color: #fff;
            position: absolute;
            top: 22%;
            transition: all .3s;
            opacity: 0;
            @include res(left, 1px, 5 / 1);
            @include res(width,8px, 6 / 8);
            @include res(height,8px, 6 / 8);
          }
          &:hover{
            color: $color-main;
            &::after{
              background-color: $color-main;
            }
          }
          &.active{
            &::after{
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

//about-banner
.about-banner{
  align-items: stretch;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  z-index: 2;
  @include res(border-bottom-left-radius, 60px, 20 / 60);
  @include res(display,flex,(sm:block));
  .left{
    flex-shrink: 0;
    position: relative;
    background-position: center;
    background-size: cover;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    @include res(width,67.7%);
    @include res(height,480px, 200 / 480);
    @include res(padding-left, 100px, 10 / 100);
    @include res(padding-bottom,80px, 20 / 80);
    @media screen and (max-width:1440px) {
      @include res(width,60%,(md:55%,sm:100%));
    }
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.3);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    h1{
      font-family: 'hs-m';
      color: #fff;
      position: relative;
      z-index: 2;
      @include res(font-size, 90px, 30 / 90);
    }
  }
  .right{
    flex-shrink: 0;
    box-sizing: border-box;
    background-color: $color-main;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    @include res(padding-top,25px, 15 / 25);
    @include res(padding-left,30px, 15 / 30);
    @include res(padding-right,30px, 15 / 30);
    @include res(display,flex);
    @include res(width,32.3%);
    @media screen and (max-width:1440px) {
      @include res(width,40%,(md:45%,sm:100%));
    }
    .circle{
      position: absolute;
      border-radius: 50%;
      background-color: #1ab3af;
      z-index: 1;
      &.circle1{
        @include res(top,-10%);
        @include res(left,-20%);
        @include res(width,400px, 200 / 400);
        @include res(height,400px, 200 / 400);
      }
      &.circle2{
        @include res(right,-10%);
        @include res(bottom,-35%);
        @include res(width,300px, 160 / 300);
        @include res(height,300px, 160 / 300);
      }
    }
    .crumbs,.about-nav{
      position: relative;
      z-index: 2;
    }
    .crumbs{
      font-family: 'hs-l';
      display: flex;
      align-items: center;
      color: #fff;
      @include res(margin-bottom,0,(sm:20px));
      @include res(font-size, 14px, 12 / 14);
      span{
        margin: 0 5px;
      }
    }
    .about-nav{
      flex-grow: 1;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      @include res(display,flex);
      div{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        white-space: nowrap;
        flex-wrap: wrap;
        a{
          flex-shrink: 0;
          text-align: center;
          border:1px solid rgba(255,255,255,1);
          border-radius: 50px;
          font-family: 'hs-m';
          color: rgba(255,255,255,1);
          transition: all .3s;
          @include res(padding-left,10px);
          @include res(padding-right,10px);
          @include res(padding-top,10px, 5 / 10);
          @include res(padding-bottom,8px, 4 / 8);
          @include res(font-size, 26px, 14 / 26);
          @include res(margin-bottom, 20px, 10 / 20);
          @include res(min-width, 48.5%,(sm:24%,xs:48.5%));
          &:hover,&.active{
            background-color: #fff;
            color: $color-main;
          }
        }
        .placeholder{
          @include res(min-width, 48.5%,(sm:24%,xs:48.5%));
        }
      }
    }
  }
}

.page{
  display: flex;
  align-items: center;
  justify-content: center;
  @include res(margin-top,90px, 25 / 90);
  a{
    border-radius: 50%;
    background-color: #e8fcfb;
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    box-sizing: border-box;
    padding-top: 3px;
    @include res(font-size, 20px, 16 / 20);
    @include res(width,36px);
    @include res(height,36px);
    &:not(:first-child){
      @include res(margin-left,18px, 5 / 18);
    }
    &:hover{
      color: $color-main;
    }
    &.active{
      background-color: $color-main;
      color: #fff;
    }
  }
}

//产品列表
.pro-list{
  @include res(padding-left,140px, 10 / 140);
  @include res(padding-right,140px, 10 / 140);
  h1{
    font-family: 'hs-m';
    color: #000;
    @include res(font-size, 50px, 30 / 50);
    @include res(margin-bottom, 100px, 20 / 100);
  }
  .list{
    &.diagnostic{
      ul li .item-img{
        @include res(height,340px,(mmd:260px,md:220px,xs:180px));
      }
    }
    &.operating{
      ul li .item-img{
        @include res(height,230px,(mmd:200px,md:120px,xs:100px));
      }
    }
    &.ultrasound{
      ul li .item-img{
        @include res(height,450px,(mmd:360px,md:280px,xs:230px));
      }
    }
    ul{
      align-items: stretch;
      justify-content: space-between;
      flex-wrap: wrap;
      @include res(display,flex);
      li{
        flex-shrink: 0;
        @include res(width,20.1%,(sm:31%,xs:48%));
        @include res(margin-bottom,60px, 20 / 60);
        &:hover{
          .item-img{
            // img.default{
            //   opacity: 0;
            // }
            img.active{
              opacity: 1;
            }
          }
          .item-text .title{
            color: $color-main;
          }
        }
        .item-img{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          @include res(margin-bottom,30px, 15 / 30);
          img{
            display: block;
            max-width: 90%;
            max-height: 100%;
            transition: all .3s;
            &.active{
              position: absolute;
              top: 50%;
              left: 50%;
              opacity: 0;
              transform: translate3d(-50%, -50%, 0);
            }
          }
        }
        .item-text{
          text-align: center;
          .title{
            font-family: 'hs-m';
            color: #000;
            transition: all .3s;
            @include res(font-size, 30px, 20 / 30);
            @include res(margin-bottom,20px, 10 / 20);
          }
          .desc{
            font-family: 'hs-l';
            color: $color-desc;
            @include res(font-size, 20px, 14 / 20);
          }
        }
      }
      .placeholder{
        @include res(width,20.1%,(sm:31%,xs:48%));
      }
    }
  }
}

//产品详情页
.pro-article-banner{
  align-items: stretch;
  justify-content: space-between;
  overflow: hidden;
  @include res(border-bottom-left-radius,60px, 20 / 60);
  @include res(display,flex,(sm:block));
  // @include res(min-height, 480px);
  .left{
    flex-shrink: 0;
    box-sizing: border-box;
    background-color: $color-main;
    @include res(width,46.87%,(sm:100%));
    @include res(padding-left,100px, 10 / 100);
    @include res(padding-right,100px, 10 / 100);
    @include res(padding-top,25px, 15 / 25);
    @include res(padding-bottom,30px, 15 / 30);
    .crumbs{
      font-family: 'hs-l';
      display: flex;
      align-items: center;
      color: #fff;
      @include res(font-size, 14px, 12 / 14);
      @include res(margin-bottom, 30px, 15 / 30);
      span{
        margin: 0 5px;
      }
    }
    .banner-swiper{
      align-items: center;
      justify-content: center;
      overflow: hidden;
      @include res(display,flex,(xs:block));
      .swiper{
        margin: 0;
      }
      .mySwiper2{
        flex-shrink: 0;
        @include res(width,70%,(xs:100%));
        @include res(max-width,370px);
        @include res(height,370px,(xs:260px));
        @include res(margin-bottom,0,(xs:20px));
        .swiper-slide{
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            display: block;
            max-width: 90%;
            max-height: 96%;
          }
        }
      }
      .mySwiper{
        flex-shrink: 0;
        @include res(margin-left, 20px,(xs:0));
        @include res(max-width,110px,(xs:100%));
        @include res(width,30%,(xs:100%));
        @include res(height,310px,(xs:80px));
        .swiper-slide{
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: .6;
          transition: opacity .3s;
          cursor: pointer;
          &.swiper-slide-thumb-active{
            opacity: 1;
          }
          img{
            display: block;
            max-width: 94%;
            max-height: 95%;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .right{
    flex-shrink: 0;
    box-sizing: border-box;
    background-color: #f1f4f4;
    @include res(width,53.13%,(sm:100%));
    @include res(padding-left,80px, 10 / 80);
    @include res(padding-right,100px, 10 / 100);
    @include res(padding-top,75px, 15 / 75);
    @include res(padding-bottom,30px, 15 / 30);
    h1{
      font-family: 'hs-m';
      color: #000;
      @include res(font-size, 60px, 30 / 60);
      @include res(margin-bottom,20px, 10 / 20);
    }
    h5{
      color: #000;
      @include res(font-size, 20px, 16 / 20);
    }
    .specifications-btn{
      color: $color-main;
      @include res(margin-top,15px, 10 / 15);
      a{
        cursor: pointer;
        font-family: 'hs-l';
        .iconfont{
          font-size: 14px;
          margin-left: 5px;
        }
      }
    }
    .line{
      height: 2px;
      background-color: $color-main;
      @include res(margin-top,40px, 15 / 40);
      @include res(margin-bottom,40px, 15 / 40);
      @include res(width,100px, 60 / 100);
    }
    .link{
      @include res(margin-top,50px, 20 / 50);
      a{
        line-height: 1.5;
        font-family: 'hs-l';
        &:hover{
          color: #000;
        }
      }
    }
    .application{
      .title{
        font-family: 'hs-m';
        color: #000;
        @include res(font-size, 30px, 18 / 30);
        @include res(margin-bottom,25px, 10 / 25);
      }
      .tag{
        span{
          display: inline-block;
          border: 1px solid #ababab;
          border-radius: 6px;
          color: #999;
          font-family: 'hs-l';
          @include res(font-size, 14px, 12 / 14);
          @include res(margin-right,10px, 5 / 10);
          @include res(margin-bottom,10px, 5 / 10);
          @include res(padding-left, 12px, 6 / 12);
          @include res(padding-right, 12px, 6 / 12);
          @include res(padding-top, 6px, 5 / 6);
          @include res(padding-bottom, 3px, 0 / 3);
        }
      }
    }
    .desc{
      line-height: 1.5;
      font-family: 'hs-l';
    }
  }
}

.features{
  @include res(padding-top, 80px, 20 / 80);
  h1{
    font-family: 'hs-m';
    color: $color-main;
    text-align: center;
    @include res(font-size, 50px, 30 / 50);
    @include res(margin-bottom, 20px, 10 / 20);
  }
  ul>li{
    align-items: center;
    justify-content: space-between;
    @include res(display,flex,(xs:block));
    @include res(padding-top, 60px, 15 / 60);
    @include res(padding-bottom, 60px, 15 / 60);
    @include res(padding-left, 130px, 10 / 130);
    @include res(padding-right, 130px, 10 / 130);
    .item-text{
      flex-shrink: 0;
      @include res(width, 47%,(xs:100%));
      h2{
        font-family: 'hs-m';
        color: #000;
        @include res(font-size, 30px, 18 / 30);
        @include res(margin-bottom, 15px, 10 / 15);
      }
      .desc{
        font-family: 'hs-l';
        line-height: 1.5;
        p{
          @include res(margin-bottom, 10px, 5 / 10);
        }
      }
    }
    .item-img{
      flex-shrink: 0;
      @include res(width, 51%,(xs:100%));
      @include res(margin-top, 0,(xs:10px));
      img{
        display: block;
        max-width: 100%;
        width: auto;
        height: auto;
        margin: 0 auto;
      }
    }
    &:nth-child(even){
      flex-direction: row-reverse;
      background-color: #f1f4f4;
    }
  }
}

.surgery{
  background-color: #f1f4f4;
  @include res(padding-top,60px, 30 / 60);
  @include res(padding-bottom,20px, 10 / 20);
  @include res(padding-left,350px, 10 / 350);
  @include res(padding-right,350px, 10 / 350);
  h1{
    font-family: 'hs-m';
    color: #000;
    text-align: center;
    @include res(font-size, 30px, 20 / 30);
    @include res(margin-bottom, 80px, 20 / 80);
  }
  ul>li{
    align-items: center;
    justify-content: space-between;
    @include res(display,flex,(xs:block));
    @include res(margin-bottom,120px, 30 / 120);
    .item-text{
      flex-shrink: 0;
      @include res(width, 48%,(xs:100%));
      h2{
        color: #000;
        display: flex;
        align-items: center;
        @include res(font-size, 24px, 16 / 24);
        @include res(margin-bottom, 15px, 10 / 15);
        img{
          @include res(width,40px);
          @include res(margin-right, 20px, 10 / 20);
        }
      }
      .desc{
        font-family: 'hs-l';
        line-height: 1.5;
        @include res(padding-left, 60px, 50 / 60);
        @media screen and (max-width: 767px){
          padding-left: 0;
        }
      }
    }
    .item-img{
      flex-shrink: 0;
      @include res(width, 45%,(xs:100%));
      @include res(margin-top, 0,(xs:10px));
      img{
        display: block;
        max-width: 100%;
        width: auto;
        height: auto;
        margin: 0 auto;
      }
    }
    &:nth-child(odd){
      flex-direction: row-reverse;
      .item-text{
        @include res(padding-left, 50px, 0 / 50);
      }
    }
  }
}

.application-scenario{
  @include res(margin-top, 140px, 40 / 140);
  ul>li{
    align-items: center;
    justify-content: space-between;
    @include res(display,flex,(xs:block));
    @include res(padding-left, 200px, 10 / 200);
    @include res(padding-right, 200px, 10 / 200);
    @include res(margin-bottom, 50px, 30 / 50);
    .item-text{
      flex-shrink: 0;
      @include res(width, 48%,(xs:100%));
      h2{
        font-family: 'hs-m';
        color: #000;
        @include res(font-size, 30px, 18 / 30);
        @include res(margin-bottom, 15px, 10 / 15);
      }
      .desc{
        font-family: 'hs-l';
        line-height: 1.5;
        max-width: 580px;
      }
    }
    .item-img{
      flex-shrink: 0;
      @include res(width, 48%,(xs:100%));
      @include res(margin-top, 0,(xs:10px));
      img{
        display: block;
        max-width: 100%;
        width: auto;
        height: auto;
        margin: 0 auto;
      }
    }
    &:nth-child(odd){
      flex-direction: row-reverse;
    }
  }
}

.advantage{
  @include res(margin-top, 120px, 40 / 120);
  h1{
    font-family: 'hs-m';
    color: $color-main;
    text-align: center;
    @include res(font-size, 50px, 30 / 50);
    @include res(margin-bottom, 80px, 20 / 80);
  }
  ul{
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    @include res(display,flex,(sm:block));
    li{
      display: flex;
      flex-shrink: 0;
      align-items: flex-start;
      @include res(width, 48%,(sm:100%));
      @include res(margin-bottom, 50px, 15 / 50);
      .item-img{
        flex-shrink: 0;
        @include res(margin-right,20px, 10 / 20);
        @include res(width,68px, 50 / 68);
        img{
          width: 100%;
          height: auto;
        }
      }
      .item-text{
        flex-grow: 1;
        h5{
          color: #000;
          @include res(margin-bottom, 10px, 5 / 10);
          @include res(font-size, 20px, 16 / 20);
        }
        .desc{
          color: $color-desc;
          font-family: 'hs-l';
          line-height: 1.375;
        }
      }
    }
  }
}

.highlights{
  background-color: #f1f4f4;
  @include res(padding-top, 60px, 30 / 60);
  @include res(padding-bottom, 60px, 30 / 60);
  @include res(padding-left,300px, 10 / 300);
  @include res(padding-right,300px, 10 / 300);
  h1{
    font-family: 'hs-m';
    color: $color-main;
    text-align: center;
    @include res(font-size, 50px, 30 / 50);
    @include res(margin-bottom, 80px, 20 / 80);
  }
  .cont{
    align-items: center;
    justify-content: space-between;
    @include res(display, flex,(sm:block));
    .left-img{
      flex-shrink: 0;
      @include res(width,42%,(sm:100%));
      img{
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
      }
    }
    .right-text{
      flex-shrink: 0;
      @include res(width,54%,(sm:100%));
      @include res(margin-top,0,(sm:30px, xs:20px));
      .item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:not(:last-child){
          @include res(margin-bottom,30px, 15 / 30);
        }
        .icon{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          @include res(width,50px);
          @include res(height,50px);
          @include res(margin-right,30px, 10 / 30);
          img{
            display: block;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
        }
        .t{
          flex-grow: 1;
          h5{
            color: #000;
            @include res(font-size, 20px, 16 / 20);
            @include res(margin-bottom, 10px, 5 / 10);
          }
          p{
            font-family: 'hs-l';
            color: $color-desc;
            @include res(font-size, 18px, 14 / 18);
          }
        }
      }
    }
  }
}

.pro-images{
  @include res(padding-top, 100px, 30 / 100);
  // @include res(padding-bottom, 60px, 30 / 60);
  @include res(padding-left,225px, 10 / 225);
  @include res(padding-right,225px, 10 / 225);
  h1{
    font-family: 'hs-m';
    color: $color-main;
    text-align: center;
    @include res(font-size, 50px, 30 / 50);
    @include res(margin-bottom, 80px, 20 / 80);
  }
  ul{
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    @include res(display,flex);
    li{
      flex-shrink: 0;
      cursor: pointer;
      @include res(width, 30.5%,(xs:48.5%));
      @include res(margin-bottom,50px, 10 / 50);
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .placeholder{
      @include res(width, 30.5%,(xs:48.5%));
    }
  }
}

.pro-full-img{
  padding-left: 10px;
  padding-right: 10px;
  @include res(padding-top,120px, 20 / 120);
  @include res(padding-bottom,70px, 20 / 70);
  img{
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

.pro-common-form{
  padding: 0 10px;
  @include res(margin-bottom, 120px, 40 / 120);
  @include res(margin-top, 150px, 40 / 150);
  h1{
    font-family: 'hs-m';
    color: $color-main;
    text-align: center;
    @include res(font-size, 50px, 30 / 50);
    @include res(margin-bottom, 80px, 20 / 80);
  }
  form{
    max-width: 1016px;
    margin: 0 auto;
    .form-item{
      flex-shrink: 0;
      position: relative;
      z-index: 1;
      border-bottom: 1px solid #000;
      @include res(margin-bottom, 15px, 5 / 15);
      &.active{
        label{
          @include res(font-size, 16px, 12 / 16);
          @include res(top,-30px, -15 / -30);
        }
      }
      label{
        position: absolute;
        left: 2px;
        z-index: -1;
        transition: $anime-duration $anime-bezier;
        color: #000;
        @include res(top, -5px, 0 / -5);
        @include res(font-size, 20px, 16 / 20);
        @include res(line-height,56px, 40 / 56);
      }
      input{
        width: 100%;
        background: none;
        border: none;
        outline: none;
        color: #000;
        @include res(font-size, 20px, 16 / 20);
        @include res(height,56px, 40 / 56);
      }
    }
    .form-btn{
      display: flex;
      @include res(justify-content,center);
      @include res(margin-top, 70px, 20 / 70);
      button{
        background-color: transparent;
        border-radius: 3px;
        color: $color-main;
        font-family: 'hs-m';
        border: 1px solid $color-main;
        cursor: pointer;
        padding-top: 5px;
        transition: all .3s;
        border-radius: 10px;
        @include res(font-size,20px, 16 / 20);
        @include res(padding-left, 30px, 20 / 30);
        @include res(padding-right, 30px, 20 / 30);
        @include res(padding-top, 10px, 8 / 10);
        @include res(padding-bottom, 5px, 3 / 5);
        &:hover{
          background-color: $color-main;
          color: #fff;
        }
      }
    }
  }
}

.fixed-specifications{
  display: none;
  @include res(padding-top, 60px, 30 / 60);
  @include res(padding-left, 40px, 10 / 40);
  @include res(padding-right, 40px, 10 / 40);
  @include res(padding-bottom, 40px, 10 / 40);
  h1{
    text-align: center;
    font-family: 'hs-m';
    color: $color-main;
    @include res(font-size, 50px, 30 / 50);
    @include res(margin-bottom, 30px, 15 / 30);
  }
  .table{
    table{
      tr{
        font-family: 'hs-l';
        color: #999;
        border-bottom: 1px solid #999999;
        @include res(font-size, 20px, 14 / 20);
        td{
          @include res(padding-left,10px, 5 / 10);
          @include res(padding-right,10px, 5 / 10);
          @include res(padding-top,20px, 10 / 20);
          @include res(padding-bottom,10px, 5 / 10);
          &:first-child{
            font-family: 'hs-m';
          }
        }
      }
    }
  }
}

.green{
  color: $color-main;
}